import { NgClass } from '@angular/common';
import { Component, OnInit, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import {
  CompiereDataGridFilterType,
  CompiereDataGridSortModelType,
  DataStoreRequest,
} from '@compiere-ws/models/compiere-data-json';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { UploadedFile } from '@iupics-components/models/uploaded-file';
import InputFileUiComponent from '@iupics-components/standard/fields/input-file-ui/input-file-ui.component';
import GridTabRowdataComponent from '@iupics-components/standard/grid/grid-tab-rowdata/grid-tab-rowdata.component';
import ModalUiComponent from '@iupics-components/standard/layouts/modal-ui/modal-ui.component';
import { AbstractDataContainer } from '@iupics-manager/models/abstract-datacontainer';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { IupicsTableDataHeader } from '@iupics-manager/models/iupics-data';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { Observable, zip } from 'rxjs';
import SpecificWindowUiComponent from '../specific-window-ui/specific-window-ui.component';
@Component({
  selector: 'iu-import-file-loader-window-ui',
  templateUrl: './import-file-loader-window-ui.component.html',
  styleUrls: ['./import-file-loader-window-ui.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgClass,
    ProgressBarModule,
    ScrollPanelModule,
    ButtonModule,
    GridTabRowdataComponent,
    ModalUiComponent,
    TranslateModule,
  ],
})
export default class ImportFileLoaderWindowUiComponent extends SpecificWindowUiComponent implements OnInit {
  //#region VCR
  @ViewChild('vcrParameters', { read: ViewContainerRef, static: true }) vcrParameters: ViewContainerRef;
  @ViewChild('vcrProcess', { read: ViewContainerRef, static: true }) vcrProcess: ViewContainerRef;
  @ViewChild('grid', { read: GridTabRowdataComponent, static: true }) gridPreview: GridTabRowdataComponent;
  //#endregion

  #fileContents: { [docid: string]: string } = {};

  #impFormat: {};
  #impFormatRow: {}[];

  constructor() {
    super();
    this.isModal = false;
    this.customDesignArray.push(
      {
        vcr: 'vcrParameters',
        type: CustomDesignItemType.FIELD,
        columnName: 'AD_ImpFormat_ID',
        cssClass: 'p-col-12',
      },
      {
        vcr: 'vcrParameters',
        type: CustomDesignItemType.FIELD,
        columnName: 'Filepath',
        cssClass: 'p-col-12',
        isLabelDisplay: false,
      },
      {
        vcr: 'vcrProcess',
        type: CustomDesignItemType.FIELD,
        columnName: 'Processing',
        cssClass: 'p-col-12',
      }
    );
  }

  ngOnInit() {
    super.ngOnInit();
    const item: DynamicComponent = {
      container: this,
      DOMParentComponent: this,
      component: 'SpecificWindowUiComponent',
      cssClass: 'p-col-12',
      isCssOnComponent: false,
      tabId: this.formId,
      gridPaginator: false,
    };
    this.windowFactory.newEventHandler({
      type: IupicsTypeEvent.showSpecificWindow,
      item: item,
    });
  }

  notifyFromDatacontainerInit(dataContainer: AbstractDataContainer) {
    if (dataContainer.data.columnName === 'Filepath' && dataContainer instanceof InputFileUiComponent) {
      this.subscriptions.push(
        dataContainer.delete.subscribe((uploadedFile: UploadedFile) => {
          this.#onDelete(uploadedFile);
        })
      );
    }
  }

  notifyFromDataChange(item: any) {
    if (item.data['columnName'] === 'Filepath') {
      const fileContainer = this.dataContainers.find(
        (container) => container instanceof InputFileUiComponent
      ) as InputFileUiComponent;
      this.#fileContents = {};
      this.#readFile(fileContainer.localFiles[0], fileContainer.uploadedFiles[0]).subscribe((docId) => {
        this.gridPreview.setRowData([]);
        this.#parseFile(this.#fileContents[docId]);
      });
    } else if (
      item.data['columnName'] === 'AD_ImpFormat_ID' &&
      this.dataStore.data[item.data['columnName']] instanceof Object
    ) {
      const impFormatRequest: DataStoreRequest = {
        windowId: this.formId,
        parent_constraint: '',
        compiereRequest: {
          startRow: 0,
          endRow: 0,
          tableName: 'AD_ImpFormat',
          filterModel: {
            AD_IMPFORMAT_ID: {
              filterType: CompiereDataGridFilterType.SET,
              values: [this.dataStore.data[item.data['columnName']].id],
              operators: [OperatorFilterType.EQUALS],
            },
          },
        },
      };

      const impFormatRowRequest: DataStoreRequest = {
        windowId: this.formId,
        parent_constraint: '',
        compiereRequest: {
          startRow: 0,
          endRow: 0,
          tableName: 'AD_ImpFormat_Row',
          filterModel: {
            AD_IMPFORMAT_ID: {
              filterType: CompiereDataGridFilterType.SET,
              values: [this.dataStore.data[item.data['columnName']].id],
              operators: [OperatorFilterType.EQUALS],
            },
          },
          sortModel: [
            {
              colId: 'SEQNO',
              sort: CompiereDataGridSortModelType.ASC,
            },
          ],
        },
      };

      zip(this.store.getDataGrid(impFormatRequest), this.store.getDataGrid(impFormatRowRequest)).subscribe(
        ([header, rows]) => {
          this.#impFormat = header.data[0];
          this.#impFormatRow = rows.data;
          // this.gridPreview.setColumnTableHeaders([]);
          const columnDefs: IupicsTableDataHeader[] = [];
          for (const field of rows.data) {
            columnDefs.push({
              field: field['NAME'],
              headerName: field['NAME'],
              enableRowGroup: true,
              width: 'min-content',
              editable: false,
            });
          }
          this.gridPreview.setColumnTableHeaders(columnDefs);
        }
      );
    }
  }

  #readFile(file: File, uploadedFile: UploadedFile) {
    return new Observable<string>((s) => {
      const reader = new FileReader();
      reader.addEventListener('load', (ev: ProgressEvent<FileReader>) => {
        this.#fileContents[uploadedFile.docId] = reader.result as string;
        s.next(uploadedFile.docId);
        s.complete();
      });
      reader.readAsText(file);
    });
  }

  #parseFile(fileContent: string) {
    let separator = '';
    switch (this.#impFormat['FORMATTYPE']) {
      case 'C':
        separator = ',';
        break;
      case 'F':
        separator = '';
        break;
      case 'S':
        separator = ';';
        break;
      case 'T':
        separator = '\t';
        break;
    }

    const rowsData = [];
    const rows = fileContent.split('\n');
    for (const row of rows) {
      if (row.trim() === '') continue;
      const rowData = {};
      let i = 0;
      if (separator) {
        // SEPARATOR
        const fieldsData = row.split(separator);
        if (fieldsData.length > 0) {
          for (const columnDef of this.#impFormatRow) {
            // DATATYPE = C => CONSTANTVALUE
            if (columnDef['DATATYPE'] === 'C') {
              rowData[this.#impFormatRow[i++]['NAME']] = columnDef['CONSTANTVALUE'];
            } else {
              rowData[this.#impFormatRow[i++]['NAME']] = fieldsData[columnDef['STARTNO'] - 1];
            }
          }
        }
      } else {
        // POSITION FIXE
        for (const columnDef of this.#impFormatRow) {
          if (columnDef['STARTNO'] > 0 && columnDef['ENDNO'] <= row.length) {
            rowData[this.#impFormatRow[i++]['NAME']] = row.substring(columnDef['STARTNO'] - 1, columnDef['ENDNO']);
          }
        }
      }
      rowsData.push(rowData);
    }
    this.gridPreview.setRowData(rowsData);
  }

  reset(event?: Event) {
    event?.stopPropagation();
    this.gridPreview.setRowData([]);
    const fileContainer = this.dataContainers.find(
      (container) => container instanceof InputFileUiComponent
    ) as InputFileUiComponent;
    fileContainer?.reset();
  }

  #onDelete(uploadedFile: UploadedFile) {
    delete this.#fileContents[uploadedFile.docId];
    this.gridPreview.setRowData([]);
    const keys = Object.keys(this.#fileContents);
    for (let i = 0; i < keys.length; i++) {
      const fileContent = this.#fileContents[keys[i]];
      this.#parseFile(fileContent);
    }
  }
}
