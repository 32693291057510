import {
  EventEmitter,
  inject,
  Injectable,
  Injector,
  Optional,
  Signal,
  signal,
  SkipSelf,
  WritableSignal,
} from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { createInjectionToken } from '@iupics/apiz-grid';
import { iif, map, merge, Observable, of, pairwise, startWith, Subject, switchMap } from 'rxjs';
import { UICreatorService } from '../ui-creator/ui-creator.service';

@Injectable()
export class SmartButtonManagerService {
  readonly #injector = inject(Injector);
  readonly #uiCreatorService = inject(UICreatorService);

  readonly registration = this.#register();
  readonly click = new EventEmitter<{ key: number; id: string }>();

  #register(): SmartButtonRegistration {
    const tabIdSignal = signal<number>(undefined);
    const recordIdSignal = signal<string>(undefined);
    const updateButtons$ = new Subject<SmartButtonData[]>();
    const data = this.#getSmartButtons(tabIdSignal, recordIdSignal, updateButtons$);

    return {
      tabId: tabIdSignal.asReadonly(),
      setTabId: (value) => tabIdSignal.set(value),
      recordId: recordIdSignal.asReadonly(),
      setRecordId: (value) => recordIdSignal.set(value),
      updateData: (callBack) => updateButtons$.next(callBack(data())),
      data,
    };
  }

  #getSmartButtons(
    tabId: WritableSignal<number>,
    recordIdSignal: WritableSignal<string>,
    updateButtons$: Subject<SmartButtonData[]>
  ) {
    return toSignal(
      merge(
        toObservable(recordIdSignal, { injector: this.#injector }).pipe(
          map((recordId) => {
            if (!recordId) {
              return { tabId: undefined, columnKey: undefined, recordId: undefined };
            }
            const [columnKey, recId] = recordId.split(',');
            return { tabId: tabId(), columnKey, recordId: parseFloat(recId) };
          }),
          switchMap(({ tabId, columnKey, recordId }) =>
            iif(
              () => tabId !== undefined && columnKey !== undefined && recordId !== undefined,
              this.#uiCreatorService.getZoomTarget(tabId, columnKey, recordId) as Observable<SmartButtonData[]>,
              of<SmartButtonData[]>([])
            )
          ),
          startWith(<SmartButtonData[]>[]),
          pairwise(),
          map(([prev, next]) => {
            const oldOpenedButtons = prev.filter((b) => b?.isOpened);
            return next.map((sb) => {
              // TODO: maybe found a better way to do that
              sb.isOpened = oldOpenedButtons.find((s) => s.key === sb.key)?.isOpened ?? false;
              if (sb.isOpened) {
                this.#emit(sb);
              }

              return sb;
            });
          })
        ),
        updateButtons$
      ),
      { injector: this.#injector }
    );
  }

  update(tabId: number, recordId: string) {
    this.registration.setTabId(tabId);
    this.registration.setRecordId(recordId);
  }

  toggleSmartButton(key: number) {
    const index = this.registration.data().findIndex((s) => s.key === key);
    if (index === -1) {
      return;
    }

    let isOpened: boolean;
    this.registration.updateData((buttons) => {
      buttons[index].isOpened = !buttons[index].isOpened;
      isOpened = buttons[index].isOpened;
      return [...buttons];
    });

    if (isOpened) this.#emit(this.registration.data()[index]);
  }

  #emit(sb: SmartButtonData) {
    this.click.emit({ key: sb.key, id: sb.id });
  }
}

export type SmartButtonRegistrationUpdateDataFn = (old: SmartButtonData[]) => SmartButtonData[];

export type SmartButtonRegistration = {
  tabId: Signal<number>;
  setTabId: (value: number) => void;
  recordId: Signal<string>;
  setRecordId: (value: string) => void;
  updateData: (callback: SmartButtonRegistrationUpdateDataFn) => void;
  data: Signal<SmartButtonData[]>;
};

export type SmartButtonData = {
  name: string;
  key: number;
  id: string;
  icon: string;
  isOpened: boolean;
};

export const [injectSmartButtonManagerService, provideSmartButtonManagerService] = createInjectionToken(
  (parentInjector: Injector, service?: SmartButtonManagerService) => {
    if (!service) {
      const injector = Injector.create({
        providers: [{ provide: SmartButtonManagerService }],
        parent: parentInjector,
      });
      service = injector.get(SmartButtonManagerService);
    }

    return service;
  },
  { isRoot: false, deps: [Injector, [new Optional(), new SkipSelf(), SmartButtonManagerService]] }
);
