import { Component, Input } from '@angular/core';
import { AbstractDynamicComponent } from '@iupics-manager/models/abstract-dynamic-component';
import { IupicsTableDataHeader } from '@iupics-manager/models/iupics-data';
import { IupicsEvent } from '@iupics-manager/models/iupics-event';
import {
  ApizGridHeadlessComponent,
  DataSourceGetDataParams,
  injectColumnApiService,
  injectGridApiService,
  provideColumnApiService,
  provideGridApiService,
} from '@iupics/apiz-grid';

@Component({
  selector: 'iu-grid-tab-rowdata',
  templateUrl: './grid-tab-rowdata.component.html',
  styleUrls: ['./grid-tab-rowdata.component.scss'],
  standalone: true,
  imports: [ApizGridHeadlessComponent],
  providers: [provideGridApiService(), provideColumnApiService()],
})
export default class GridTabRowdataComponent extends AbstractDynamicComponent {
  @Input() columnsTableHeader: IupicsTableDataHeader[] = [];

  api = injectGridApiService();
  columnApi = injectColumnApiService();

  set rowData(rowData: any[]) {
    this.setRowData(rowData);
  }

  set columnTableHeaders(columnTableHeaders: IupicsTableDataHeader[]) {
    this.setColumnTableHeaders(columnTableHeaders);
  }

  constructor() {
    super();
    this.setRowData([]);
  }

  onChildUpdate(event): void {}
  onSiblingUpdate(event: IupicsEvent) {}
  onRemoveComponent(event: IupicsEvent) {}

  /** You should pass an immutable array. */
  setRowData(rowData: any[]) {
    this.api.setDatasource({
      getData: (params: DataSourceGetDataParams) => params.success({ data: rowData, rowCount: rowData.length }),
    });
  }

  /** You should pass an immutable array. */
  setColumnTableHeaders(columnTableHeaders: IupicsTableDataHeader[]) {
    this.api.updateOptions('columnDefs', columnTableHeaders);
  }
}
